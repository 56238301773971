import type { ActionFunctionArgs, LoaderFunctionArgs } from "@remix-run/node";
import { MetaFunction, json, redirect } from "@remix-run/node";
import { Form, Link, useActionData, useNavigation } from "@remix-run/react";
import { Loader2 } from "lucide-react";
import { useState } from "react";
import ThemeSwitcher from "~/components/ThemeSwitcher";
import { LoginResponse, loginUser } from "~/server/endpoints/auth.server";
import { getSession } from "~/server/sessions.server";
import { Button } from "~/shadcn/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "~/shadcn/components/ui/card";
import { isErrorResponse } from "~/types";

export const meta: MetaFunction = () => {
  return [{ title: "Vendur - Login" }, { name: "description", content: "Welcome to Vendur IO!" }];
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const session = await getSession(request.headers.get("Cookie"));
  const credentials = session.get("credentials");
  if (credentials) {
    return redirect("/dashboard");
  }
  return json({});
};

export async function action({ request }: ActionFunctionArgs) {
  const urlObject = new URL(request.url);
  const urlParams = urlObject.searchParams;
  const ref = urlParams && urlParams.get("ref");
  const session = await getSession(request.headers.get("Cookie"));
  const body = await request.formData();
  const username = body.get("username") as string | null;
  const password = body.get("password") as string | null;

  if (!username || !password) {
    return json({ error: "Username and password are required" }, { status: 400 });
  }

  const payload = { username, password };

  try {
    const response: LoginResponse = await loginUser(session, payload);
    if (response.status !== 200) {
      return json({ error: response.error }, { status: response.status });
    }
    return redirect(ref ? `/${ref}` : "/dashboard", {
      headers: response.headers || {},
    });
  } catch (error) {
    console.error(error);
    if (isErrorResponse(error)) {
      return json(
        { error: error.response?.data?.message || "username or password is incorrect." },
        { status: error.response?.status || 500 }
      );
    }
    return json({ error: "username or password is incorrect." }, { status: 500 });
  }
}

export default function LoginRoute() {
  const navigation = useNavigation();
  const actionData = useActionData<{ error?: string }>();
  const [usernameError, _setUsernameError] = useState<string | null>(null);
  const [passwordError, _setPasswordError] = useState<string | null>(null);

  const inputs = [
    {
      displayName: "Username",
      name: "username",
      type: "text",
      placeholder: "johndoe",
      error: usernameError,
    },
    {
      displayName: "Password",
      name: "password",
      type: "password",
      placeholder: "********",
      error: passwordError,
    },
  ];

  return (
    <div className="flex items-center justify-center min-h-screen">
      <Card className="w-full max-w-sm border-none bg-simpleCard shadow-lg drop-shadow-lg dark:shadow-card dark:drop-shadow-card">
        <Form method="post">
          <CardHeader>
            <div className="flex justify-between items-center">
              <CardTitle className="text-2xl">Login</CardTitle>
              <ThemeSwitcher></ThemeSwitcher>
            </div>
            <CardDescription>Enter your username below to login to your account.</CardDescription>
          </CardHeader>
          <CardContent className="grid gap-4">
            {actionData?.error && <p className="text-red-500">{actionData.error}</p>}
            {inputs.map((input, index) => (
              <div key={`login-form-${index}`}>
                <div className="relative">
                  <input
                    type={input.type}
                    id={input.name}
                    name={input.name}
                    required
                    className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=""
                  />
                  <label
                    htmlFor={input.name}
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
                  >
                    {input.displayName}
                  </label>
                </div>
                {input.error && (
                  <p className="mt-2 text-xs text-red-600 dark:text-red-400">
                    <span className="font-medium">Oh, snapp!</span>
                    {input.error}
                  </p>
                )}
              </div>
            ))}
          </CardContent>
          <CardFooter>
            <div className="flex flex-col w-full items-center space-y-5">
              <div className="w-full h-8">
                {navigation.state === "submitting" ? (
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                ) : (
                  <Button type="submit" className="w-full" variant="default">
                    Login
                  </Button>
                )}
              </div>
              <Link to="/onboard" className="text-center text-blue-500 hover:underline">
                Create an account
              </Link>
            </div>
          </CardFooter>
        </Form>
      </Card>
    </div>
  );
}
